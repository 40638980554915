import React from "react"

import Layout from "../components/_shared/Layout"
import SEO from "../components/_shared/SEO"
import PolicySection from "../components/_shared/PolicySection"

import privacyPolicyHTML from "!!raw-loader!../policies/privacy.html"

// ----------------------------------------------------------------------------
const PrivacyPage = () => (
  <Layout>
    <SEO title="Privacy" />

    <PolicySection title="Privacy Policy" policyHTML={privacyPolicyHTML} />
  </Layout>
)

export default PrivacyPage
