import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"

import Divider from "../Divider"

//------------------------------------------------------------------------------

function PolicySection(props) {
  // -------------------------------------
  // Props destructuring
  // -------------------------------------

  const { policyHTML } = props

  // -------------------------------------
  // Hooks (e.g. useState, useMemo ...)
  // -------------------------------------

  // -------------------------------------
  // Effects
  // -------------------------------------

  // -------------------------------------
  // Component functions
  // -------------------------------------

  // -------------------------------------
  // Component local variables
  // -------------------------------------

  return (
    <div className={props.className}>
      <Divider />

      <div
        className="policy-content"
        dangerouslySetInnerHTML={{ __html: policyHTML }}
      />
    </div>
  )
}

// ----------------------------------------------------------------------------
// Component PropTypes and default props
// ----------------------------------------------------------------------------

PolicySection.propTypes = {
  className: PropTypes.string.isRequired,
  title: PropTypes.string,
  policyHTML: PropTypes.string,
}

PolicySection.defaultProps = {
  title: "",
  policyHTML: "",
}

// ----------------------------------------------------------------------------

const StyledPolicySection = styled(PolicySection)`
  & {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: auto;
    padding-top: 122px;
    padding-bottom: 140px;
    width: 720px;
    max-width: 720px;
    min-height: 100%;

    h1,
    h2,
    h3,
    p,
    span {
      color: ${(props) => props.theme.primaryTextColor};
    }

    h2,
    h3 {
      font-weight: 600;
    }

    > h1 {
      margin-bottom: 31.5px;
    }

    h2 {
      margin-bottom: 14px;
    }

    h3 {
      margin-bottom: 6px;
    }

    p {
      margin-bottom: 37px;
    }

    table {
      th,
      td {
        padding: 15px;
        text-align: left;
      }

      th,
      td {
        vertical-align: center;
      }
    }

    .policy-content {
      margin-top: 25px;
      width: 100%;

      iframe {
        width: 100%;
        height: 100vh;
        border: none;
        background-color: #fff;
      }
    }

    /* MOBILE */

    @media (max-width: ${(props) => props.theme.mediaQueries.mobile}) {
      width: 90vw;
      max-width: 90vw;

      /* Force table to not be like tables anymore */
      table,
      thead,
      tbody,
      th,
      td,
      tr {
        display: block;
      }

      thead tr {
        position: absolute;
        top: -9999px;
        left: -9999px;
      }

      tr {
        border: 1px solid #ccc;
      }

      td {
        /* Behave  like a "row" */
        border: none;
        border-bottom: 1px solid #eee;
        position: relative;
        padding-left: 50%;
      }
    }
  }
`
// ----------------------------------------------------------------------------

export default StyledPolicySection
